import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";

const SectionTitle = ({ title, isPhoneOrTablet }) => {
  const id = title.replace(" ", "-").toLowerCase();
  return (
    <Flex id={id} color={"#fff"} alignItems={"center"} justifyContent={"center"} mb={8} mt={8}>
      <Divider w={10} />
      <Text fontSize={"2xl"} ml={2} mr={2} textAlign={"center"}>
        {title}
      </Text>
      <Divider w={10} />
    </Flex>
  );
};

export default SectionTitle;
