import { Flex, Image, LinkBox, LinkOverlay, Portal } from "@chakra-ui/react";
import React from "react";
import { logoSVG } from "../../assets";
import Navigation from "./Navigation/Navigation";

const Header = ({ isPhoneOrTablet }) => {
  return (
    <Flex justifyContent={"center"} m={3}>
      <Flex maxW={"1064px"} width={"full"} justifyContent={"space-between"} p={isPhoneOrTablet ? 3 : 5} color={"#fff"} alignItems={"center"}>
        <LinkBox>
          <LinkOverlay href="/">
            <Image src={logoSVG} />
          </LinkOverlay>
        </LinkBox>
        <Navigation isPhoneOrTablet={isPhoneOrTablet} />
      </Flex>
    </Flex>
  );
};

export default Header;
