import { EmailIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, Image, Link, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { footerLogo } from "../../assets";
import { Header, Landing, SectionTitle } from "../../components";
import Projects from "../../components/Projects/Projects";
import WorkExperience from "../../components/WorkExperience/WorkExperience";

const Home = () => {
  const [isPhoneOrTablet] = useMediaQuery("(max-width: 768px)");

  return (
    <Flex direction={"column"} background={"#1D1D1D"} minH={"100vh"}>
      <Header isPhoneOrTablet={isPhoneOrTablet} />
      <Landing isPhoneOrTablet={isPhoneOrTablet} />
      <Projects isPhoneOrTablet={isPhoneOrTablet} />

      <Flex id={"skills"} bgColor={"rgba(28, 97, 97, 0.88)"} mt={16} direction={"column"} alignItems={"center"}>
        <SectionTitle title={"Skills"} />
        <Flex maxW={"1064px"} width={"full"} pl={"10"} pr={"10"} gap={3} justifyContent={"center"} wrap={"wrap"} color={"#fff"} mb={8}>
          <Heading fontWeight={"normal"}>HTML</Heading>
          <Heading fontWeight={"normal"}>CSS</Heading>
          <Heading fontWeight={"normal"} color={"orange"}>
            C#
          </Heading>
          <Heading fontWeight={"normal"}>jQuery</Heading>
          <Heading fontWeight={"normal"} color={"orange"}>
            React
          </Heading>

          <Heading fontWeight={"normal"}>Github</Heading>
          <Heading fontWeight={"normal"} color={"orange"}>
            JavaScript
          </Heading>
          <Heading fontWeight={"normal"}>Chakra UI</Heading>
          <Heading fontWeight={"normal"}>Material UI</Heading>
          <Heading fontWeight={"normal"} color={"orange"}>
            .NET
          </Heading>
          <Heading fontWeight={"normal"} color={"orange"}>
            Figma
          </Heading>
          <Heading fontWeight={"normal"}>Symfony</Heading>
        </Flex>
      </Flex>

      <WorkExperience isPhoneOrTablet={isPhoneOrTablet} />

      {/* <Box id={"about-me"} bgColor={"rgba(28, 97, 97, 0.88)"} mt={16}>
        <SectionTitle title={"About Me"} />
        <Flex pl={"10"} pr={"10"} gap={3} justifyContent={"center"} wrap={"wrap"} color={"#fff"} mb={16}>
          <Text>I love bouldering and anime!</Text>
        </Flex>
      </Box> */}

      <Flex id={"contact"} mt={16} justifyContent={"center"}>
        <Flex
          maxW           = {"1064px"}
          width          = {isPhoneOrTablet ? "md" : "full"}          
          padding        = {3}
          gap            = {3}
          justifyContent = {"center"}
          alignItems     = {"center"}
          wrap           = {"wrap"}
          color          = {"#fff"}
          bgColor        = {"#E04B0C"}
          borderRadius   = {isPhoneOrTablet ? "none" : "md"}
          zIndex         = {999}
          direction      = {isPhoneOrTablet ? "column" : "row"}
        >
          <Text fontWeight={"medium"} fontSize={"lg"} textAlign={"center"}>
            Have a question or want to work together?
          </Text>
          <Box p={"3"} borderRadius={"md"} borderWidth={isPhoneOrTablet && "1px"}>
            <EmailIcon mr={"2"} />
            <Link href="mailto: hello@joshmccabe.dev">hello@joshmccabe.dev</Link>
          </Box>
        </Flex>
      </Flex>
      {/* Footer */}
      <Flex justifyContent={"center"} pb={8} pt={20} borderTop={"1px solid #363636"} mt={"-14"} background={"#363636"}>
        <Image src={footerLogo} />
      </Flex>
    </Flex>
  );
};

export default Home;
