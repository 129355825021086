import { DownloadIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Link, ListItem, SimpleGrid, Text, UnorderedList } from "@chakra-ui/react";
import React from "react";
import SectionTitle from "../SectionTitle/SectionTitle";

const WorkExperience = ({ isPhoneOrTablet }) => {
  return (
    <Flex mt={8} direction={"column"} alignItems={"center"}>
      <SectionTitle title={"Work Experience"} isPhoneOrTablet={isPhoneOrTablet} />
      {/* <Button colorScheme={"orange"} variant={"outline"} leftIcon={<DownloadIcon />} mb={5} borderRadius={"sm"} size={"sm"}>
        MY RESUME
      </Button> */}
      <SimpleGrid columns={isPhoneOrTablet ? 1 : 2} maxW={"1064px"} width={"full"} pl={"5"} pr={"5"} gap={"30px"}>
        <Box bgColor={"#363636"} color={"#fff"} p={"4"}>
          <Flex gap={"1"}>
            <Text fontWeight={"bold"}>Front End Developer</Text>
            <Link href="https://tdsultra.com" target={"_blank"}>
              <Text fontStyle={"italic"} color={"#64FFDA"}>
                @ TDS Ultra
              </Text>
            </Link>
          </Flex>
          <Text fontWeight={"thin"} fontSize={"sm"}>
            May 2022 - Present // Europa House, Southwick, Brighton
          </Text>
          <UnorderedList mt={"3"} fontSize={"sm"}>
            <ListItem>Implement and maintain features of TDS web apps using React/Redux and Symfony.</ListItem>
            <ListItem>Design new features using Figma.</ListItem>
          </UnorderedList>
        </Box>
        <Box bgColor={"#363636"} color={"#fff"} p={"4"}>
          <Flex gap={"1"}>
            <Text fontWeight={"bold"}>Software Developer</Text>
            <Link href="https://iqmsoftware.co.uk" target={"_blank"}>
              <Text fontStyle={"italic"} color={"#64FFDA"}>
                @ IQM Software
              </Text>
            </Link>
          </Flex>
          <Text fontWeight={"thin"} fontSize={"sm"}>
            Nov 2018 - April 2022 // Hove Technology Centre, Brighton
          </Text>
          <UnorderedList mt={"3"} fontSize={"sm"}>
            <ListItem>Engineer and maintain major features of IQM’s customer-facing web app using ASP.NET Core, HTML, CSS, Javascript.</ListItem>
            <ListItem>Design new features using Figma.</ListItem>
            <ListItem>UI testing using Selenium and unit test with NUnit.</ListItem>
          </UnorderedList>
        </Box>
      </SimpleGrid>
    </Flex>
  );
};

export default WorkExperience;
