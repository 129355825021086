import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";

const Landing = ({ isPhoneOrTablet }) => {
  return (
    <Flex justifyContent={"center"}>
      <Flex direction={"column"} color={"#fff"} maxW={"1064px"} w={"full"} p={5} gap={"20px"} alignItems={"center"} textAlign={"center"}>
        <Text fontFamily={"Questrial"} fontSize={"2xl"}>
          Hi👋 I'm Josh.
        </Text>
        <Heading textAlign={"center"} size={"2xl"} fontWeight={"normal"}>
          I design and develop websites.
        </Heading>
        <Box>
          <Button colorScheme={"orange"} size={"md"} borderRadius={"sm"} onClick={() => (location.hash = "contact")} mt={3}>
            Get in touch
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Landing;
