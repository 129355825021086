import { ChevronDownIcon, ChevronUpIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Button, Flex, Heading, Image, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { bobTheCaveman, icrc, iqmPortal, musicPlayer } from "../../assets";
import SectionTitle from "../SectionTitle/SectionTitle";

const ProjectCard = ({isPhoneOrTablet, projectHeader, projectDescription, projectImage}) => {
  return (
    <Flex 
      bgColor   = {"#363636"}
      padding   = {"4"}
      color     = {"#fff"}
      direction = {"column"}
    >
      {/* Project Header/Title */}
      <Heading 
        textAlign    = {"center"}
        fontWeight   = {"light"}
        marginBottom = {"4"}
        fontSize     = {"2xl"}
      >
        {projectHeader}
      </Heading>
      {/* Project Image */}
      <Image 
        src          = {projectImage}
        borderRadius = {"sm"}
        mb           = {"4"}
      />
      {/* Project Description */}
      <Flex flexGrow={1}>
        <Text mb={"6"}>
          {projectDescription}
        </Text>
      </Flex>
      {/* Project Link */}
      <Button
        onClick   = {() => open("https://bit.ly/3Ko9DnI", "_blank")}
        variant   = {"ghost"}
        fontSize  = {"sm"}
        _hover    = {{ opacity: 0.8 }}
        alignSelf = {"flex-end"}
        _active   = {{ color: "#fff", border: "1px solid #fff" }}
        rightIcon = {<ExternalLinkIcon />}
      >
        READ MORE
      </Button>
    </Flex>
  )
}

const Projects = ({ isPhoneOrTablet }) => {  
  const [showMore, setShowMore] = useState(true);

  return (
    <Flex mt={16} direction={"column"} alignItems={"center"}>
      <SectionTitle title={"Projects"} isPhoneOrTablet={isPhoneOrTablet} />
      <SimpleGrid maxW={"1064px"} width={"full"} pl={"5"} pr={"5"} columns={isPhoneOrTablet ? 1 : 2} gap={"30px"}>
        {/* project cards */}
        <Flex bgColor={"#363636"} p={"4"} color={"#fff"} direction={"column"}>
          <Heading textAlign={"center"} fontWeight={"light"} mb={"4"} fontSize={"2xl"}>
            IQM Portal
          </Heading>
          <Image src={iqmPortal} borderRadius={"sm"} mb={"4"} />
          <Flex flexGrow={1}>
            <Text mb={"6"}>
              Web app built using ASP.NET Core and Material UI. Redesign of a product portal with the goal of improving user experience.
            </Text>
          </Flex>
          <Button
            onClick   = {() => open("https://bit.ly/3Ko9DnI", "_blank")}
            variant   = {"ghost"}
            fontSize  = {"sm"}
            _hover    = {{ opacity: 0.8 }}
            alignSelf = {"flex-end"}
            _active   = {{ color: "#fff", border: "1px solid #fff" }}
            rightIcon = {<ExternalLinkIcon />}
          >
            READ MORE
          </Button>
        </Flex>
        {!isPhoneOrTablet && (
          <Flex bgColor={"#363636"} p={"4"} color={"#fff"} direction={"column"} alignItems={"center"}>
            <Heading textAlign={"center"} fontWeight={"light"} mb={"4"} fontSize={"2xl"}>
              ICRC Memorial
            </Heading>
            <Image src={icrc} borderRadius={"sm"} mb={"4"} />
            <Flex flexGrow={1} mb={"6"}>
              <Text>This project was to design a website where people can commemorate and remember those who died in service for ICRC.</Text>
            </Flex>
            <Button
              onClick   = {() => open("https://www.figma.com/file/kElQMm7szCKZBwNlk9QGjw/alternative-designs", "_blank")}
              variant   = {"ghost"}
              fontSize  = {"sm"}
              _hover    = {{ opacity: 0.8 }}
              alignSelf = {"flex-end"}
              _active   = {{ color: "#fff", border: "1px solid #fff" }}
              rightIcon = {<ExternalLinkIcon />}
            >
              GO TO FIGMA
            </Button>
          </Flex>
        )}
        {showMore && (
          <>
            {isPhoneOrTablet && (
              <Flex bgColor={"#363636"} p={"4"} color={"#fff"} direction={"column"} alignItems={"center"}>
                <Heading textAlign={"center"} fontWeight={"light"} mb={"4"} fontSize={"2xl"}>
                  ICRC Memorial
                </Heading>
                <Image src={icrc} borderRadius={"sm"} mb={"4"} />
                <Flex flexGrow={1} mb={"6"}>
                  <Text>This project was to design a website where people can commemorate and remember those who died in service for ICRC.</Text>
                </Flex>
                <Button
                  onClick   = {() => open("https://www.figma.com/file/kElQMm7szCKZBwNlk9QGjw/alternative-designs", "_blank")}
                  variant   = {"ghost"}
                  fontSize  = {"sm"}
                  _hover    = {{ opacity: 0.8 }}
                  alignSelf = {"flex-end"}
                  _active   = {{ color: "#fff", border: "1px solid #fff" }}
                  rightIcon = {<ExternalLinkIcon />}
                >
                  GO TO FIGMA
                </Button>
              </Flex>
            )}
            <Flex bgColor={"#363636"} p={"4"} color={"#fff"} direction={"column"} alignItems={"center"}>
              <Heading textAlign={"center"} fontWeight={"light"} mb={"4"} fontSize={"2xl"}>
                Music Player
              </Heading>
              <Image src={musicPlayer} borderRadius={"sm"} mb={"4"} />
              <Flex flexGrow={1} mb={"6"}>
                <Text>For my final apprenticeship project I was tasked to create a music player. I created this using React and Material UI.</Text>
              </Flex>

              <Button
                onClick={() =>
                  open("https://gabby-apricot-4b8.notion.site/Synoptic-Project-L4-Music-Player-624ea4527f084396a1a6f3f2a122b8f1", "_blank")
                }
                variant={"ghost"}
                fontSize={"sm"}
                _hover={{ opacity: 0.8 }}
                alignSelf={"flex-end"}
                _active={{ color: "#fff", border: "1px solid #fff" }}
                rightIcon={<ExternalLinkIcon />}
              >
                READ MORE
              </Button>
            </Flex>
            <Flex bgColor={"#363636"} p={"4"} color={"#fff"} direction={"column"} alignItems={"center"}>
              <Heading textAlign={"center"} fontWeight={"light"} mb={"4"} fontSize={"2xl"}>
                Bob The Caveman
              </Heading>
              <Image src={bobTheCaveman} borderRadius={"sm"} mb={"4"} />
              <Flex flexGrow={1} mb={"6"}>
                <Text>A coming soon landing page.</Text>
              </Flex>
              <Button
                onClick={() => open("https://bobthecaveman.com", "_blank")}
                variant={"ghost"}
                fontSize={"sm"}
                _hover={{ opacity: 0.8 }}
                alignSelf={"flex-end"}
                _active={{ color: "#fff", border: "1px solid #fff" }}
                rightIcon={<ExternalLinkIcon />}
              >
                VISIT SITE
              </Button>
            </Flex>
          </>
        )}
      </SimpleGrid>
      <Button
        fontSize={"sm"}
        variant={"ghost"}
        color={"#fff"}
        fontWeight={"light"}
        rightIcon={showMore ? <ChevronUpIcon /> : <ChevronDownIcon />}
        _hover={{ opacity: 0.8 }}
        _active={{ color: "#fff", border: "1px solid #fff" }}
        mt={"3"}
        onClick={() => setShowMore(!showMore)}
      >
        {showMore ? "SHOW LESS" : "SHOW MORE"}
      </Button>
    </Flex>
  );
};

export default Projects;
