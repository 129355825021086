import { HamburgerIcon } from "@chakra-ui/icons";
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, Flex, IconButton, Link, useDisclosure } from "@chakra-ui/react";
import React, { useRef } from "react";

const Navigation = ({ isPhoneOrTablet }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {isPhoneOrTablet && (
        <>
          <IconButton
            size={"lg"}
            onClick={onOpen}
            icon={<HamburgerIcon />}
            variant={"ghost"}
            colorScheme={"gray"}
            _hover={{ color: "#000", background: "#fff" }}
          />
          <Drawer isOpen={isOpen} placement="right" onClose={onClose} trapFocus={false} size={"md"}>
            <DrawerOverlay />
            <DrawerContent bgColor={"#363636"} color={"#fff"}>
              <DrawerCloseButton />
              <DrawerBody mt={16}>
                <Flex gap={10} direction={"column"} fontSize={"xl"}>
                  <Link href="#projects" onClick={onClose}>
                    Projects
                  </Link>
                  <Link href="#work-experience" onClick={onClose}>
                    Work
                  </Link>
                  {/* <Link href="#about-me" onClick={onClose}>
                    About
                  </Link> */}
                  <Link href="#contact" onClick={onClose}>
                    Contact
                  </Link>
                </Flex>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      )}
      {!isPhoneOrTablet && (
        <Flex gap={5}>
          <Link href="#projects">Projects</Link>
          <Link href="#work-experience">Work</Link>
          {/* <Link href="#about-me">About</Link> */}
          <Link href="#contact">Contact</Link>
        </Flex>
      )}
    </>
  );
};

export default Navigation;
