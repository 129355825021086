import { createRoot } from "react-dom/client";
import App from "../src/App";
import React, { StrictMode } from "react";

createRoot(document.getElementById('app')).render(
    <StrictMode>
        <App />
    </StrictMode>
);


