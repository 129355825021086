import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Blog, Home, Project } from "./pages";

const App = () => {
  const theme = extendTheme({
    fonts: {
      heading: `'Abril Fatface', cursive`,
      body: `'Roboto', sans-serif`,
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/project/:id" element={<Project />} />
          <Route exact path="/blog" element={<Blog />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;
